<template>
  <div>
    <c-card title="화재탐지 및 경보설비 설치계획 목록 업로드 (동일한 설치지역은 자료가 수정됩니다.)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'device-equipment-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['installLocation'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_FIRE_DETECTION_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:110px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'installLocation',
            field: 'installLocation',
            // 설치지역
            label: 'LBL0010501',
            style: 'width:150px',
            align: 'center',
            type: 'link',
            sortable: false,
          },
          {
            name: 'singleAlarm',
            field: 'singleAlarm',
            // 단독경보형감지기
            label: 'LBL0010519',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'emergencyAlarm',
            field: 'emergencyAlarm',
            // 비상경보설비
            label: 'LBL0010520',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'startAlarm',
            field: 'startAlarm',
            // 시작경보기
            label: 'LBL0010521',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'autoDetection',
            field: 'autoDetection',
            // 자동화재탐지설비
            label: 'LBL0010522',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'emergencyBroadcast',
            field: 'emergencyBroadcast',
            // 비상방송설비
            label: 'LBL0010523',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'autoBreakingnews',
            field: 'autoBreakingnews',
            // 자동화재속보설비
            label: 'LBL0010524',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'integrationSystem',
            field: 'integrationSystem',
            // 통합감시시설
            label: 'LBL0010525',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'shortAlarm',
            field: 'shortAlarm',
            // 누전경보기
            label: 'LBL0010526',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:250px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '에러가 있는 데이터가 존재합니다.\n\r데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '해당 엑셀데이터를 업로드하면,\n\r동일한 설치지역은 자료가 수정됩니다 \n\r그대로 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
